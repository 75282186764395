<template>
  <div class="container">
    <BasicTitle title="Cuenta" />
    <!-- <BasicSubtitle
      subtitle="Los campos señalados con (*) son obligatorios."
    /> -->
    <div class="container-form">
      <BorderInput v-model="user.name" label="Nombre" @keyup.enter="onSave" />
      <FormError :show="userError.name" message="Ingrese un nombre" />
      <BorderInput v-model="user.identity" label="CI" @keyup.enter="onSave" />
      <FormError
        :show="userError.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BorderInput
        v-model="user.email"
        :disabled="true"
        label="Email"
        @keyup.enter="onSave"
      />
      <FormError :show="userError.email" message="Ingrese un email válido" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="user.phone"
          label="Teléfono"
          @keyup.enter="onSave"
        />
      </div>
      <FormError :show="userError.phone" message="Ingrese un teléfono válido" />
      <BorderInput
        v-model="user.password"
        label="Nueva contraseña"
        type="password"
        @keyup.enter="onSave"
      />
      <FormError
        :show="userError.password"
        message="La contraseña debe cumplir todos los requisitos"
      />
      <BorderInput
        v-model="user.confirmPassword"
        label="Repita la contraseña"
        type="password"
        @keyup.enter="onSave"
      />
      <FormError
        :show="userError.confirmPassword"
        message="Las contraseñas no coinciden"
      />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../widgets/title/BasicTitle";
import BasicSubtitle from "../widgets/subtitle/BasicSubtitle";
import BorderInput from "../widgets/input/BorderInput";
import BorderSelect from "../widgets/select/BorderSelect";
import BorderInputPrefix from "../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../widgets/input/BorderInputPhone";
import FormError from "../widgets/error/FormError";
import PrimaryButton from "../widgets/button/PrimaryButton";
import store from "../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { validation } from "../constants";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const user = reactive({
      name: "",
      identity: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    });
    const userError = reactive({
      name: false,
      identity: false,
      email: false,
      phone: false,
      password: false,
      confirmPassword: false,
    });

    watchEffect(() => {
      if (user.name) userError.name = false;
      if (user.identity && validation.validateCI(user.identity))
        userError.identity = false;
      if (user.email && validation.emailRegex.test(user.email))
        userError.email = false;
      if (user.phone && validation.phoneRegex.test(user.phone))
        userError.phone = false;
      if (user.password && validation.passwordRegex.test(user.password))
        userError.password = false;
      if (user.password && user.password === user.confirmPassword)
        userError.confirmPassword = false;
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setBreadcrumbs", [
        { label: "Inicio", to: "/home" },
        { label: "Cuenta", to: route.path },
      ]);
      const selected = store.state.auth.user;
      user.name = selected.name;
      user.identity = selected.identity;
      user.email = selected.email;
      user.phone = selected.phone ? selected.phone.split(" ")[1] : "";
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    const validate = () => {
      let valid = true;

      if (!user.name) {
        userError.name = true;
        valid = false;
      }
      if (!user.identity || !validation.validateCI(user.identity)) {
        userError.identity = true;
        valid = false;
      }
      if (!user.email || !validation.emailRegex.test(user.email)) {
        userError.email = true;
        valid = false;
      }
      if (!user.phone || !validation.phoneRegex.test(user.phone)) {
        userError.phone = true;
        valid = false;
      }
      if (user.password && !validation.passwordRegex.test(user.password)) {
        userError.password = true;
        valid = false;
      }
      if (user.password && user.password !== user.confirmPassword) {
        userError.confirmPassword = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      if (user.phone) {
        user.phone = `+598 ${user.phone}`;
      }

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_UPDATE_USER", user);
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
        router.push("/home");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    return { store, user, userError, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
